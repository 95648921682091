export default {
  props: ["nameScreen"],
  data() {
    return {
      menu: false,
      sSearch: "",
      dates: null,
      dateStart: null,
      dateEnd: null,
      screenWidth: 0
    };
  },
  mounted() {
    this.$store.commit("setSearch", "");
    // this.$store.commit("setStartDate", null);
    // this.$store.commit("setEndDate", null);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setDates() {
      this.dateStart = this.dates[0];
      this.dateEnd = this.dates[1];
    },
    focusSearch() {
      this.$refs.sSearch.focus();
    },
    handleResize() {
      // if(window.innerWidth < 920){
      //   this.screenWidth = window.innerWidth - 55;
      // }else{
      // this.screenWidth = window.innerWidth - 160;
      // }


      
    },
  },
  watch: {
    dateStart: function () {
      // this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      // this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
  },
};